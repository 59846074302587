import * as constants from './constants'
const geojson = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-122.397059, 37.789776]
            },
            properties: {
                id: "2",
                title: "Salesforce Tower",
                address: "415 Mission St, San Francisco, CA",
                walk: "15s",
                type: "residential",
                price: "230K",
                priceAmount: 230000,
                sqm: '3100',
                views: '2k',
                img: "images/product2.png",
                imgMid: "images/v2/Commercial-building-detail.png",
                imgLarge: "images/v2/product1-large.png",
                restockCar:0,
                unit_rice:100000,
            }
        },
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-122.402337, 37.792207]
            },
            properties: {
                id: "1",
                title: "US Bank",
                address: "300 Montgomery St, San Francisco, CA",
                walk: "15s",
                type: "residential",
                price: "255K",
                priceAmount: 255000,
                sqm: '2100',
                views: '2k',
                img: "images/product2.png",
                imgMid: "images/v2/Commercial-building-detail.png",
                imgLarge: "images/v2/product2-large.png",
                restockCar:0,
                unit_rice:100000,
            }
        },
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [-122.400472, 37.784244]
            },
            properties: {
                id: "0",
                title: "Moscone Center",
                address: "747 Howard St, San Francisco, CA",
                walk: "15s",
                type: "commercial",
                price: "2.1M",
                priceAmount: 2100000,
                sqm: '41000',
                views: '2k',
                img: "images/product2.png",
                imgMid: "images/v2/Commercial-building-detail.png",
                imgLarge: "images/v2/product3-large.png",
                restockCar:0,
                unit_rice:100000,
            }
        },
        {
            type: "Feature",
            properties: {
                id: "3",
                title: "Minna Building",
                address: "817 Mission St, San Francisco, CA",
                walk: "15s",
                type: "residential",
                price: "1.2M",
                priceAmount: 1200000,
                sqm: '5600',
                views: '2k',
                img: "images/product2.png",
                imgMid: "images/v2/Residential-building-detail.png",
                imgLarge: "images/v2/product5-large.png",
                restockCar:0,
                unit_rice:100000,
            },
            geometry: {
                type: "Point",
                coordinates: [-122.404704, 37.783592],
            },
        },
        {
            type: "Feature",
            properties: {
                id: "4",
                title: "Powell Land",
                address: "80 Ellis St, San Francisco, CA",
                walk: "15s",
                type: "residential",
                price: "124K",
                priceAmount: 124000,
                sqm: '3500',
                views: '2k',
                img: "images/product2.png",
                imgMid: "images/v2/Commercial-building-detail.png",
                imgLarge: "images/v2/product1-large.png",
                restockCar:0,
                unit_rice:100000,
            },
            geometry: {
                type: "Point",
                coordinates: [-122.407560, 37.785878],
            },
        },
        {
            type: "Feature",
            properties: {
                id: "5",
                title: "Marion Building",
                address: "124 Geary St, San Francisco, CA",
                walk: "15s",
                type: "residential",
                price: "813K",
                priceAmount: 813000,
                sqm: '2340',
                views: '2k',
                img: "images/product2.png",
                imgMid: "images/v2/Residential-building-detail.png",
                imgLarge: "images/v2/product2-large.png",
                restockCar:0,
                unit_rice:100000,
            },
            geometry: {
                type: "Point",
                coordinates: [-122.405417, 37.787985],
            },
        },
    ]
};
const properties= [
    {
      id: "0",
      title: "Moscone Center",
      address: "747 Howard St, San Francisco, CA",
      walk: "15s",
      type: "commercial",
      price: "2.1M",
      priceAmount: 2100000,
      sqm: '41000',
      views: '2k',
      img: "images/product2.png",
      imgMid: "images/v2/Commercial-building-detail.png",
      imgLarge: "images/v2/product3-large.png",
    },
    {
      id: "1",
      title: "US Bank",
        address: "300 Montgomery St, San Francisco, CA",
        walk: "15s",
        type: "residential",
        price: "255K",
        priceAmount: 255000,
        sqm: '2100',
        views: '2k',
        img: "images/product2.png",
        imgMid: "images/v2/Commercial-building-detail.png",
        imgLarge: "images/v2/product2-large.png",
    },
    {
      id: "2",
      title: "Salesforce Tower",
        address: "415 Mission St, San Francisco, CA",
        walk: "15s",
        type: "residential",
        price: "230K",
        priceAmount: 230000,
        sqm: '3100',
        views: '2k',
        img: "images/product2.png",
        imgMid: "images/v2/Commercial-building-detail.png",
        imgLarge: "images/v2/product1-large.png",
        restockCar:0,
        unit_rice:100000,
    },
    {
        id: "3",
        title: "Minna Building",
        address: "817 Mission St, San Francisco, CA",
        walk: "15s",
        type: "residential",
        price: "1.2M",
        priceAmount: 1200000,
        sqm: '5600',
        views: '2k',
        img: "images/product2.png",
        imgMid: "images/v2/Residential-building-detail.png",
        imgLarge: "images/v2/product5-large.png",
        restockCar:0,
        unit_rice:100000,
    },
    {
        id: "4",
        title: "Powell Land",
        address: "80 Ellis St, San Francisco, CA",
        walk: "15s",
        type: "residential",
        price: "124K",
        priceAmount: 124000,
        sqm: '3500',
        views: '2k',
        img: "images/product2.png",
        imgMid: "images/v2/Commercial-building-detail.png",
        imgLarge: "images/v2/product1-large.png",
        restockCar:0,
        unit_rice:100000,
    },
    {
        id: "5",
        title: "Marion Building",
        address: "124 Geary St, San Francisco, CA",
        walk: "15s",
        type: "residential",
        price: "813K",
        priceAmount: 813000,
        sqm: '2340',
        views: '2k',
        img: "images/product2.png",
        imgMid: "images/v2/Residential-building-detail.png",
        imgLarge: "images/v2/product2-large.png",
        restockCar:0,
        unit_rice:100000,
    }
];
const buildings = [
    {
        id: "Infiblue Auto Mall",
        priceAmount: 1200000,
    },
    {
        id: "Orchard House",
        priceAmount: 300000,
    },
    {
        id: "Highfield",
        priceAmount: 280000,
    },
    {
        id: "Mighty Oaks",
        priceAmount: 320000,
    }
];
// 初始默认的state
const defaultState = {
    myData: null,
    geojson:geojson,
    properties:properties,
    buildings:buildings,
}

const reducer = (state = defaultState, action) => {
    // 由于state是引用型，不能直接修改，否则是监测不到state发生变化的。因此需要先复制一份进行修改，然后再返回新的state。
    let newState = Object.assign({}, state)
    switch(action.type) {
        case constants.SET_DATA:
            newState.myData = action.data
            return newState
        default:
            return state
    }
}

export default reducer