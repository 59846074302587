import React, { Component, } from 'react';
import store  from '../store';
import mapboxgl from 'mapbox-gl';
import "./Map.less";

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

// eslint-disable-next-line import/no-webpack-loader-syntax
export default class Map extends Component {
    constructor() {
        super()
        this.state = {
            map: () => ({}),
            geojson:store.getState().geojson,
        }

    }
    componentDidMount() {
        this.init()
    }
    init() {
        const geojson = this.state.geojson;
        mapboxgl.workerClass = MapboxWorker;
        mapboxgl.accessToken = 'pk.eyJ1IjoiaGd1YW4zNjkiLCJhIjoiY2wzZHluMGJjMDA3OTNicGg5bWRwOHI3NyJ9.EHYEtkSyvMX8e-SZu1dMvQ';
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: "mapbox://styles/mapbox/streets-v10",
            center: [-122.402214, 37.788072],
            zoom: 14,
        });
        let _this = this;
        geojson.features.forEach(function (marker) {
            // create a HTML element for each feature
            let el = document.createElement('div');
            let icon = marker.properties.type === 'commercial' ? '<img alt="" class="icon-tiny" src="images/commercial.svg" />' : '<img alt="" class="icon-tiny" src="images/residential.svg" />';
            el.innerHTML = icon + '$' + marker.properties.price;

            let item = marker.properties;
            el.onclick = function () {
                _this.resetHighlightedMarkers();
                this.className = 'marker mapboxgl-marker marker-highlighted';
                let images = this.getElementsByClassName('icon-tiny');
                for (let i = 0; i < images.length; i++) {
                    images[i].className = 'icon-tiny icon-highlighted';
                }

                map.flyTo({ center: marker.geometry.coordinates })
                _this.subShowPoint(item)
            }
            el.className = 'marker';

            // make a marker for each feature and add to the map
            new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
        });

        let location_el = document.createElement('div');
        location_el.className = 'marker-location'

        new mapboxgl.Marker(location_el)

            .setLngLat([-122.402214, 37.788072])

            .addTo(map);

        this.setState({ map: map })
    }
    resetHighlightedMarkers() {
        let markers = document.getElementsByClassName('marker-highlighted');
        for (let i = 0; i < markers.length; i++) {
            const mkr = markers[i];
            mkr.className = 'marker mapboxgl-marker';
            let images = mkr.getElementsByClassName('icon-highlighted');
            for (let i = 0; i < images.length; i++) {
                images[i].className = 'icon-tiny';
            }
        }
    }
    subShowPoint(el) {
        this.props.showPoint(el)
    }
    toGPSLocation = () => {
        let map = this.state.map;
        map.flyTo({
            center: [-122.402214, 37.788072],
            zoom: 14,
        });
        this.setState({ map: map })
    }
    toLocation = (location) => {
        let map = this.state.map;
        map.flyTo({
            center: location,
            zoom: 14,
        });
        this.setState({ map: map })
    }
    render() {
        return (
            <div ref={el => this.mapContainer = el} className="container-map" style={{ height: '100vh', width: '100vw' }}>
            </div>
        );
    }
}

